const $ = require('jquery');


class ScrollingNav {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$prevButtonNode = this.$node.find('[data-ref~="prev"]');
        this.$nextButtonNode = this.$node.find('[data-ref~="next"]');
        this.$listNode = this.$node.find('[data-ref~="list"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');

        this.$prevButtonNode.on('click', this.handlePrevClick.bind(this));
        this.$nextButtonNode.on('click', this.handleNextClick.bind(this));
        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));

        $(window).on('load resize', () => {
            const $activeTriggerNode = this.$triggerNodes.filter('.is-active').first();
            this.scrollToTrigger($activeTriggerNode);
        });

        const $activeTriggerNode = this.$triggerNodes.filter('.is-active').first();
        this.updateNavButtons($activeTriggerNode);
    }

    handlePrevClick () {
        const $activeTriggerNode = this.$triggerNodes.filter('.is-active').first();
        const i = this.$triggerNodes.index($activeTriggerNode);
        if (i === 0) return;
        this.$triggerNodes.eq(i - 1).trigger('click');
    }

    handleNextClick () {
        const $activeTriggerNode = this.$triggerNodes.filter('.is-active').first();
        const i = this.$triggerNodes.index($activeTriggerNode);
        if (i === this.$triggerNodes.length - 1) return;
        this.$triggerNodes.eq(i + 1).trigger('click');
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.currentTarget);
        this.updateNavButtons($triggerNode);
        this.scrollToTrigger($triggerNode);
    }

    scrollToTrigger ($triggerNode) {
        const triggerNode = $triggerNode.get(0);
        const listNode = this.$listNode.get(0);

        const left = triggerNode.getBoundingClientRect().left
            - listNode.getBoundingClientRect().left;

        if ('scrollTo' in listNode) {
            listNode.scrollTo({
                top: 0,
                left: left + listNode.scrollLeft,
                behavior: 'smooth',
            });
        } else {
            listNode.scrollLeft = left;
        }
    }

    updateNavButtons ($triggerNode) {
        const i = this.$triggerNodes.index($triggerNode);
        this.$prevButtonNode.prop('disabled', i === 0);
        this.$nextButtonNode.prop('disabled', i === this.$triggerNodes.length - 1);
    }
}

module.exports = ScrollingNav;
