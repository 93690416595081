const $ = require('jquery');


class ThemeCard {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$videoNode = this.$node.find('[data-ref~="video"]');

        // XXX: These bindings include custom events which are not currently
        // used, but it was imagined they might be triggered by the containing
        // `ThemeCarousel` in response to use of the prev/next arrows
        this.$node.on('mouseenter touchstart focusin activate.card',
            this.handleActivate.bind(this));
        this.$node.on('mouseleave touchend focusout deactivate.card',
            this.handleDeactivate.bind(this));
    }

    handleActivate () {
        this.$node.addClass('is-active');
        this.$videoNode.get(0).play();
    }

    handleDeactivate () {
        this.$node.removeClass('is-active');
        this.$videoNode.get(0).pause();
    }
}

module.exports = ThemeCard;
