const $ = require('jquery');


class TestimonialCarousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.intersectionThreshold = [0, 0.25, 0.5, 0.75, 1];
        this.targetIntersectionRatio = 0.75;

        this.$listNode = this.$node.find('[data-ref~="list"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');
        this.$prevButtonNode = this.$node.find('[data-ref~="prev"]');
        this.$nextButtonNode = this.$node.find('[data-ref~="next"]');

        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));
        this.$prevButtonNode.on('click', this.handlePrevClick.bind(this));
        this.$nextButtonNode.on('click', this.handleNextClick.bind(this));
        this.bindIntersectionObserver();
    }

    bindIntersectionObserver () {
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            threshold: this.intersectionThreshold,
        });
        this.$itemNodes.get().forEach(node => observer.observe(node));
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.target);
        const i = this.$triggerNodes.index($triggerNode);
        this.jump(i);
    }

    handleIntersection (entries) {
        entries.forEach((entry) => {
            const $itemNode = $(entry.target);
            const i = this.$itemNodes.index($itemNode);
            this.$triggerNodes.eq(i).toggleClass(
                'is-active', entry.intersectionRatio >= this.targetIntersectionRatio);
        });
    }

    handlePrevClick () {
        let i = this.$triggerNodes.index(this.$triggerNodes.filter('.is-active'));
        if (i === 0) i = this.$triggerNodes.length - 1;
        else i--;
        this.jump(i);
    }

    handleNextClick () {
        let i = this.$triggerNodes.index(this.$triggerNodes.filter('.is-active'));
        if (i === this.$triggerNodes.length - 1) i = 0;
        else i++;
        this.jump(i);
    }

    jump (i) {
        const $itemNode = this.$itemNodes.eq(i);

        const itemNode = $itemNode.get(0);
        const listNode = this.$listNode.get(0);
        const left = itemNode.getBoundingClientRect().left
                     - listNode.getBoundingClientRect().left;

        if ('scrollTo' in listNode) {
            listNode.scrollTo({
                top: 0,
                left: left + listNode.scrollLeft,
                behavior: 'smooth',
            });
        } else {
            listNode.scrollLeft = left;
        }
    }
}

module.exports = TestimonialCarousel;
