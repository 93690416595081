const $ = require('jquery');
require('jsrender')($);


class Modal {
    constructor (content) {
        const template = $.templates('#tmpl-modal');
        const html = template.render();
        this.$node = $(html);

        this.$innerNode = this.$node.find('[data-ref="inner"]');
        this.$closeNode = this.$node.find('[data-ref="close"]');
        this.$contentNode = this.$node.find('[data-ref="content"]');

        this.$closeNode.on('click', this.close.bind(this));
        this.$contentNode.append(content);

        this._handleDocumentClick = this.handleDocumentClick.bind(this);
        this._handleDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
    }

    handleDocumentClick (event) {
        const $node = $(event.target);
        if (!$node.closest(this.$innerNode).length) {
            this.close();
        }
    }

    handleDocumentKeyUp (event) {
        if (event.key === 'Escape') {
            this.close();
        }
    }

    open () {
        $(document.body).append(this.$node);
        window.setTimeout(() => {
            this.$node.addClass('is-visible');
            this.$innerNode.addClass('is-visible');
        }, 0);

        $(document).on('click', this._handleDocumentClick);
        $(document).on('keyup', this._handleDocumentKeyUp);
    }

    close () {
        $(document).off('click', this._handleDocumentClick);
        $(document).off('keyup', this._handleDocumentKeyUp);

        this.$node.removeClass('is-visible');
        this.$innerNode.removeClass('is-visible');
        window.setTimeout(() => {
            this.$node.remove();
        }, 500);
    }
}

module.exports = Modal;
