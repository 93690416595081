const $ = require('jquery');


class AjaxSubNav {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$selectNode = this.$node.find('[data-ref~="select"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');

        this.$selectNode.on('change', this.handleSelectChange.bind(this));
        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));
    }

    handleSelectChange (event) {
        const $selectNode = $(event.target);
        const url = $selectNode.val();
        window.history.pushState(null, '', url);
        $(window).trigger('pushstate');
    }

    handleTriggerClick (event) {
        event.preventDefault();
        const $triggerNode = $(event.currentTarget);
        const url = $triggerNode.attr('href');
        window.history.pushState(null, '', url);
        $(window).trigger('pushstate');
    }
}

module.exports = AjaxSubNav;
