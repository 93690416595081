const $ = require('jquery');


class SimpleSubNav {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$selectNode = this.$node.find('[data-ref~="select"]');

        this.$selectNode.on('change', this.handleSelectChange.bind(this));
    }

    handleSelectChange (event) {
        const $selectNode = $(event.target);
        const url = $selectNode.val();
        window.location.href = url;
    }
}

module.exports = SimpleSubNav;
