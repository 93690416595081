const $ = require('jquery');
const throttle = require('throttle-debounce').throttle;


class Carousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.intersectionThreshold = [0, 0.25, 0.5, 0.75, 1];
        this.targetIntersectionRatio = 0.5;

        this.$navNode = this.$node.find('[data-ref~="nav"]');
        this.$prevButtonNode = this.$node.find('[data-ref~="prev"]');
        this.$nextButtonNode = this.$node.find('[data-ref~="next"]');
        this.$listNode = this.$node.find('[data-ref~="list"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');

        this.$prevButtonNode.on('click', this.handlePrevClick.bind(this));
        this.$nextButtonNode.on('click', this.handleNextClick.bind(this));
        this.$itemNodes.on('focusin', this.handleItemFocusIn.bind(this));
        this.$listNode.on('scroll', throttle(100, this.handleScroll.bind(this)));
        $(window).on('load resize', this.handleResize.bind(this));

        this.bindResizeObserver();
        this.bindIntersectionObserver();
    }

    bindIntersectionObserver () {
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            threshold: this.intersectionThreshold,
        });
        this.$itemNodes.get().forEach(node => observer.observe(node));
    }

    bindResizeObserver () {
        // Make sure the scroll/resize handler is fired when the component is
        // shown or hidden, e.g. as part of `tabbed-content`
        if ('ResizeObserver' in window) {
            // TODO: Polyfill for IE11
            const observer = new window.ResizeObserver(this.handleScroll.bind(this));
            observer.observe(this.$node.get(0));
        }
    }

    handleIntersection (entries) {
        // When an item's visibility in the viewport changes, update a data
        // property to represent this. This will be used later in the `focusin`
        // handler
        entries.forEach((entry) => {
            const $itemNode = $(entry.target);
            $itemNode.data('visible', entry.intersectionRatio >= this.targetIntersectionRatio);
        });
    }

    handleResize () {
        // Show/hide nav based on whether list has enough content to scroll
        if (this.$navNode.length) {
            const listNode = this.$listNode.get(0);
            this.$navNode.css(
                'visibility',
                listNode.scrollWidth > listNode.clientWidth
                    ? 'visible'
                    : 'hidden'
            );
        }

        // Ensure scroll updates follow a resize
        this.handleScroll();
    }

    handleScroll () {
        const scrollLeft = this.$listNode.scrollLeft();
        const clientWidth = this.$listNode.get(0).clientWidth;
        const scrollWidth = this.$listNode.get(0).scrollWidth;

        this.$prevButtonNode.prop('disabled', scrollLeft === 0);
        this.$nextButtonNode.prop('disabled', scrollLeft + clientWidth >= scrollWidth - 10);
    }

    handlePrevClick () {
        // Find final item with left edge at negative position relative to
        // container (i.e. scrolled out of view), and scroll to it
        const listNode = this.$listNode.get(0);
        const [prevItemNode, left] = this.$itemNodes.get()
            .map(itemNode => {
                const left = itemNode.getBoundingClientRect().left
                    - listNode.getBoundingClientRect().left;
                return [itemNode, left];
            })
            .filter(([, left]) => left < 0)
            .pop() || [];

        if (!prevItemNode) return;
        if ('scrollTo' in listNode) {
            listNode.scrollTo({
                top: 0,
                left: left + listNode.scrollLeft,
                behavior: 'smooth',
            });
        } else {
            listNode.scrollLeft = left;
        }
    }

    handleNextClick () {
        // Find first item with left edge at positive position relative to
        // middle of container and scroll to it
        const listNode = this.$listNode.get(0);
        const [nextItemNode, left] = this.$itemNodes.get()
            .map(itemNode => {
                const left = itemNode.getBoundingClientRect().left
                    - listNode.getBoundingClientRect().left
                    - parseInt(window.getComputedStyle(listNode).getPropertyValue('padding-left'), 10);
                return [itemNode, left];
            })
            .filter(([, left]) => left > (listNode.clientWidth / 2))
            .shift() || [];

        if (!nextItemNode) return;
        if ('scrollTo' in listNode) {
            listNode.scrollTo({
                top: 0,
                left: left + listNode.scrollLeft,
                behavior: 'smooth',
            });
        } else {
            listNode.scrollLeft = left;
        }
    }

    handleItemFocusIn (event) {
        // Scroll to items on focus if (mostly) outside viewport
        const itemNode = event.currentTarget;
        const $itemNode = $(itemNode);
        const listNode = this.$listNode.get(0);
        if ($itemNode.data('visible')) return;

        const left = itemNode.getBoundingClientRect().left
            - listNode.getBoundingClientRect().left
            - parseInt(window.getComputedStyle(listNode).getPropertyValue('padding-left'), 10);

        if ('scrollTo' in listNode) {
            listNode.scrollTo({
                top: 0,
                left: left + listNode.scrollLeft,
                behavior: 'smooth',
            });
        } else {
            listNode.scrollLeft = left;
        }
    }
}

module.exports = Carousel;
