const $ = require('jquery');


class AjaxNav {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.target = this.$node.attr('data-ajax-target');
        this.$targetNode = $(this.target);

        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');
        this.$activeTriggerNode = this.$triggerNodes.filter('.is-active');

        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));

        // Listen for native `popstate` event and custom `pushstate` event.
        // The custom `pushstate` event is fired by other components to
        // trigger an update in this component.
        $(window).on('pushstate popstate', this.handleStateChange.bind(this));

        // `scrollRestoration` configuration
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }

    handleTriggerClick (event) {
        event.preventDefault();
        const $triggerNode = $(event.currentTarget);
        if (this.$activeTriggerNode.is($triggerNode)) return;

        const url = $triggerNode.attr('href');
        window.history.pushState(null, '', url);
        $(window).trigger('pushstate');
    }

    handleStateChange () {
        const url = window.location.href;
        this.loadContent(url);

        const urlWithoutQuery = url.split('?')[0];
        const $triggerNode = this.$triggerNodes
            .filter(`[href="${urlWithoutQuery}"]`)
            .first();
        this.activateTrigger($triggerNode);
    }

    activateTrigger ($triggerNode) {
        this.$activeTriggerNode = $triggerNode;
        if ($triggerNode.hasClass('is-active')) return;

        this.$triggerNodes.removeClass('is-active');
        $triggerNode.addClass('is-active');
    }

    loadContent (url) {
        this.$targetNode.attr('data-loading', true);

        this.request && this.request.abort();
        this.request = $.ajax(url, { dataType: 'html' }).done((data) => {
            // Wrap response in an element so we can be sure `find()` will succeed
            const $new = $('<div />').html(data);

            // Find target node in response and replace current target
            const $newTargetNode = $new.find(this.target);
            this.$targetNode.replaceWith($newTargetNode);

            // Update reference to target node
            this.$targetNode = $(this.target);

            this.$targetNode.removeAttr('data-loading');

            // Trigger custom `rebind` event to initialise any components
            // within the loaded content
            this.$targetNode.trigger('rebind');
        });
    }
}

module.exports = AjaxNav;
