const $ = require('jquery');


const MODE_IMAGE = 'image';
const MODE_VIDEO = 'video';

class BackgroundGallery {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;
        this.mode = this.$node.attr('data-mode');
        this.withContent = this.$node.attr('data-with-content') === 'true';

        this.$videoNode = this.$node.find('[data-ref~="video"]');
        this.$imageNodes = this.$node.find('[data-ref~="image"]');
        this.$overlayNode = this.$node.find('[data-ref~="overlay"]');
        this.$contentNodes = this.$node.find('[data-ref~="content"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');

        if (this.withContent) {
            this.$triggerNodes.on('click',
                this.handleTriggerActivate.bind(this));
        } else {
            this.$triggerNodes.on('mouseenter touchmove focusin',
                this.handleTriggerActivate.bind(this));
        }
    }

    handleTriggerActivate (event) {
        const $triggerNode = $(event.currentTarget);
        const i = this.$triggerNodes.index($triggerNode);
        const $imageNode = this.$imageNodes.eq(i);

        this.$triggerNodes.removeClass('is-active');
        $triggerNode.addClass('is-active');

        if (this.mode === MODE_IMAGE) {
            // In image mode, just switch classes
            this.$imageNodes.removeClass('is-active');
            $imageNode.addClass('is-active');

        } else if (this.mode === MODE_VIDEO) {
            // In video mode, load the incoming video and switch src
            // XXX: Use timeout to ensure the transition of the overlay
            // completes before switching sources
            const overlayTransitionDuration = 250;
            this.$overlayNode.addClass('is-full');
            window.setTimeout(() => {
                const newSrc = $triggerNode.attr('data-video-url');
                const video = document.createElement('video');
                video.addEventListener('canplaythrough', () => {
                    this.$videoNode.attr('src', newSrc);
                    this.$overlayNode.removeClass('is-full');
                }, false);
                video.src = newSrc;
            }, overlayTransitionDuration);
        }

        if (this.$contentNodes.length) {
            const $contentNode = this.$contentNodes.eq(i);
            this.$contentNodes.removeClass('is-active');
            $contentNode.addClass('is-active');
        }
    }
}

module.exports = BackgroundGallery;
